export const API_URL = process.env['NEXT_PUBLIC_API_URL'];
export const DATA_API_URL = process.env['NEXT_PUBLIC_DATA_API_URL'];
export const FORUM_API_URL = process.env['NEXT_PUBLIC_FORUM_API_URL'];

export const COOKIES_MAP = {
  accessToken: 'VAULT-accessToken',
  refreshToken: 'VAULT-refreshToken',
  userId: 'VAULT-userId',
};

export const MOCKED_USER = {
  email: process.env['NEXT_PUBLIC_MOCKED_USER_EMAIL'],
  password: process.env['NEXT_PUBLIC_MOCKED_USER_PASSWORD'],
};

export const IS_DEVELOPMENT = process.env['NODE_ENV'] === 'development';
export const IS_PRODUCTION = process.env['NEXT_PUBLIC_PRODUCTION'];
