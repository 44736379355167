import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface IUser {
  email: string;
  id: string;
  avatar?: string;
  phone?: string;
  name: string;
  username: string;
  isOnboarded: boolean;
}

export interface AppState {
  user: IUser | null;
}

const initialState: AppState = {
  user: {
    email: '',
    id: '',
    name: '',
    username: '',
    isOnboarded: false,
  },
};

export const counterSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    clearUser: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setUser, clearUser } = counterSlice.actions;

export const userReducer = counterSlice.reducer;
