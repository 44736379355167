import { BaseQueryFn, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL as baseUrl } from '@constants/api.constants';
import { cookieService } from '@lib/cookie.service';
import { IErrorResponse, IErrorResponseSignle } from 'typings/api.typigns';

export const baseQuery = fetchBaseQuery({ baseUrl }) as BaseQueryFn<
  FetchArgs | string,
  unknown,
  IErrorResponse,
  {}
>;

const tokenQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const { accessToken, refreshToken } = cookieService.getRequestCookies();
    if (accessToken && refreshToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
      headers.set('Refresh-token', refreshToken);
    }
    return headers;
  },
}) as BaseQueryFn<FetchArgs | string, unknown, IErrorResponseSignle, {}>;

export const authQuery = async (args, api, extraOptions) => {
  let result = await tokenQuery(args, api, extraOptions);
  if (result.error && 'status' in result.error) {
    // TODO: ask about 403
    if (result.error.status === 401) {
      const { refreshToken } = cookieService.getRequestCookies();

      const response = await tokenQuery(
        {
          url: '/auth/refresh-token',
          method: 'POST',
          body: {
            refreshToken,
          },
        },
        api,
        extraOptions,
      );

      if ('data' in response) {
        cookieService.setAuthCookise(response.data as any);
        result = await tokenQuery(args, api, extraOptions);
      } else {
        // TODO: logout
      }
    }
  }
  return result;
};
