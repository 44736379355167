import { getCookie, setCookie, deleteCookie } from 'cookies-next';
import { COOKIES_MAP } from '@constants/api.constants';

export interface IAuthOptions {
  accessToken: string;
  refreshToken: string;
  userId: string;
}

export const cookieService = {
  setAuthCookise({ accessToken, refreshToken, userId }: IAuthOptions) {
    const maxAge = 60 * 60 * 24 * 365 * 5; //
    const expires = new Date(Date.now() + 60 * 60 * 24 * 365 * 5 * 1000);

    const options = {
      maxAge,
      expires,
    };

    setCookie(COOKIES_MAP.accessToken, accessToken, options);
    setCookie(COOKIES_MAP.refreshToken, refreshToken, options);
    setCookie(COOKIES_MAP.userId, userId, options);
  },
  clearAuthCookies() {
    deleteCookie(COOKIES_MAP.accessToken);
    deleteCookie(COOKIES_MAP.refreshToken);
    deleteCookie(COOKIES_MAP.userId);
  },
  getAuthCookies() {
    return {
      accessToken: getCookie(COOKIES_MAP.accessToken) || null,
      refreshToken: (getCookie(COOKIES_MAP.refreshToken) as string) || null,
      userId: getCookie(COOKIES_MAP.userId) || null,
    };
  },
  getAuthHeader() {
    const accessToken = getCookie(COOKIES_MAP.accessToken);
    const refreshToken = getCookie(COOKIES_MAP.refreshToken);
    return {
      Authorization: 'Bearer ' + accessToken,
      'Refresh-token': refreshToken ? refreshToken.toString() : '',
    };
  },
  getRequestCookies() {
    return {
      accessToken: getCookie(COOKIES_MAP.accessToken) || null,
      refreshToken: (getCookie(COOKIES_MAP.refreshToken) as string) || null,
    };
  },
  getUserId() {
    const userId = getCookie(COOKIES_MAP.userId);
    return userId ? userId.toString() : '';
  },
};
